:root {
    --light-purple: #ECD7FF;
    --medium-purple: #B7A3DB;
    --purple: #816EB7;
    --orange: #F18A1E;
    --yellow: #F7C321;
    --turquoise: #00D4E4;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15%;
    background-color: var(--medium-purple);
    height: 100px;
    z-index: 1;
}

.menu-links {
    /* Removing defaults */
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-links li {
    display: inline;
    margin-right: 20px;
}

.menu-links a {
    text-decoration: none;
    color: white;
    font-size: 25px;
}

.menu-links a:hover {
    color: var(--purple);
    transition: 0.2s ease-in-out;
}

.hamburger{
    transform: translate(-10px, -10px);
    display: none;
    cursor: pointer;
}

.bar{
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: white;
}

.mobile-logo {
    display: none;
}

/* Mobile Styling */
@media (max-width: 768px) {
    .navbar {
        padding: 0 10%;
    }

    .colossus {
        display:none;
    }

    .menu-links, .menu-links.active {
        position: absolute;
        display: flex;
        flex-direction: column;
        text-align: center;
        top: 100px;
        left: 0;
        right: 0;
        background-color: var(--medium-purple);
        transition: transform 0.6s ease-in-out;
        gap: 30px;
        padding: 5% 0;
        z-index: 2;
    }

    .menu-links {
        transform: translateY(-200%);
    }

    .menu-links.active {
        transform: translateY(-33%);
    }

    .mobile-logo {
        display: block;
        transform: translateX(25px);
    }

    .hamburger, .mobile-logo {
        z-index: 3;
    }

    .hamburger {
        display: block;
        height: 3px;
        width: 7px;
        color: white;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}